<template>
  <div id="global-box">
    <header>
      <img src="../../assets/title.svg" alt="天津东疆保税港区" class="img" />
    </header>
    <main>
      <section class="buttons">
        <input
            type="file"
            id="File"
            ref="fileRef"
            style="display: none;"
            @change="imgToBase64"
        />
        <a-button
            type="primary"
            @click="chooseImage"
            style="margin-right: 20px; font-size: 17px; height: 40px"
        >
          上传待识别图片
        </a-button>
        <a-button
            @click="useJDapi"
            :disabled="base64Url.length === 0"
            :loading="isLoading"
            style="font-size: 17px; height: 40px"
        >
          识别图片
        </a-button>
      </section>
      <section class="results">
        <img src="" ref="imgRef" style="display: block; width: 79%; margin: 8px auto" alt="">
        <a-table :dataSource="dataGarbage" :columns="columns" v-show="dataGarbage.length !== 0"/>
      </section>
    </main>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {message} from "ant-design-vue";
import OSS from "ali-oss";
export default defineComponent({
  data() {
    return {
      photoUrl: "",
      isLoading: false,
      dataGarbage: [],
      columns: [
        {
          title: '名称',
          dataIndex: 'garbage_name',
          key: 'garbage_name',
          align: "center",
          width: '17%'
        },
        {
          title: '类别',
          dataIndex: 'cate_name',
          key: 'cate_name',
          align: "center",
          width: '17%'
        },
        {
          title: '可信度',
          dataIndex: 'confidence',
          key: 'confidence',
          align: "center",
          width: '18%'
        },
        {
          title: '建议',
          dataIndex: 'ps',
          key: 'ps',
          align: "center",
          width: '48%'
        }
      ],
      base64Url: ""
    }
  },
  methods: {
    //选择需要识别的图片
    //选择图片
    chooseImage() {
      this.$refs.fileRef.dispatchEvent(new MouseEvent("click"));
    },
    //获取需要转化的图片
    imgToBase64(e) {
      let that = this;
      this.photoUrl = '';
      this.dataGarbage = [];
      let file = e.target.files[0];
      e.target.value = '';
      let obj = new Date().getTime();
      let storeAs = obj + "." + file.name;
      const client = new OSS({
        region: "oss-cn-beijing",
        accessKeyId: "LTAI5tAhC8qbUaiTHPh7dEuA",
        accessKeySecret: "TfALWBoft5i0A2P11Vxd1DGqBqj9bE",
        bucket: "rubbish-demo",
      });
      if (this.beforeUpload(file)) {
        client.put(storeAs, file).then(results => {
          that.photoUrl = results.url
        });
        let imgFile = new FileReader();
        imgFile.readAsDataURL(file);
        imgFile.onload = function () {
          that.$refs.imgRef.src = this.result;
          that.base64Url = this.result.toString().replace('data:image/jpeg;base64,', '');
        }
      }
      this.dataGarbage = [];
    },
    //图片转base64格式
    beforeUpload(file) {
      const jspicture =
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/jpg";
      if (!jspicture) {
        message.error(file.name + "上传文件格式错误");
      }
      return jspicture;
    },
    //调用京东api并返回结果
    useJDapi() {
      this.isLoading = true;
      this.$axios({
        url: "http://39.100.158.75:8080/other/getPhotoInfo",
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          photoUrl: this.photoUrl
        })
      }).then(res => {
        this.photoUrl = "";
        this.isLoading = false;
        this.dataGarbage = res.data.data.result.garbage_info;
        for (const el of this.dataGarbage) {
          el.confidence = (el.confidence * 100).toFixed(2) + '%';
        }
      });
    }
  }
})

</script>

<style lang="scss" scoped>
#global-box {
  width: 100vw;
  position: relative;
  header {
    width: 100%;
    position: absolute;
    height: 1.5rem;
    .img {
      display: block;
      width: 2.8519rem;
      padding: 0.2rem;
    }
  }
  main {
    width: 100%;
    position: absolute;
    top: 1.5rem;
    padding: 0.1rem;
    .buttons {
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>